import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import { routes } from '../../routes'

export default function AuthView({ children }) {
  const history = useHistory()
  const auth = useSelector(state => state.firebase.auth)

  const userHomePath = routes.home.path
  

  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      history.push(userHomePath)
    }
  }, [auth, history, userHomePath])

  return (
    <Container>
      <Row className="text-center justify-content-center">
        <Col xs={12} md={6} lg={4} className="mt-3">
          {children}
        </Col>
      </Row>
    </Container>
  )
}
