import React from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import "./UserCheck.css";

export default function UserCheck() {
  const { uid } = useSelector(state => state.firebase.auth)
  
  useFirestoreConnect({
    collection: `/users/${uid}/buildings`,
    orderBy: ['created_at', 'desc'],
    storeAs: 'buildings'
  })
  
  return (
    <div className="check-user-wrapper">
      <div className="check-user-inner">
        <h3>Checking user status</h3>
      </div>
    </div>
  );
}
