import React, { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link, useParams } from "react-router-dom"
import useUserFirestore from '../../hooks/useUserFirestore'
import { connect } from 'react-redux'
import MyFormSection from '../../Components/Layout/MyFormSection'
import MyPage from '../../Components/Layout/MyPage'
import useNotify from '../../hooks/useNotify'

const mapStateToProps = (state) => ({
  selectedBuilding: state.global.selectedBuilding,
  flats: state.firestore.data.flats
})

function EditFlat({ selectedBuilding, flats }) {
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit } = useForm()
  const firestore = useUserFirestore()
  const { id } = useParams()
  const flat = flats ? flats[id] : null
  const { toast } = useNotify()

  const updateFlat = (form, e) => {
    setSubmitting(true)
    firestore
      .collection('buildings')
      .doc(selectedBuilding)
      .collection('flats')
      .doc(id)
      .update(form)
      .then(() => {
        setSubmitting(false)
        toast.success('Επιτυχής ενημέρωση', {
          toastId: "editFlatSuccess"
        })
      })
      .catch(err => {
        setSubmitting(false)
        toast.error('Κάτι πήγε στραβά', {
          toastId: 'editFlatError'
        })
      })
  }

  return (
    <MyPage title="Επεξεργασία διαμερίσματος">
      <Form onSubmit={handleSubmit(updateFlat)}>
        {flat &&
          <Row>
            <Col>
              <MyFormSection headerText="Γενικά στοιχεία">
                <Form.Group controlId="number">
                  <Form.Label>Αρίθμηση</Form.Label>
                  <Form.Control ref={register} type="text" name="number" defaultValue={flat.number} required />
                </Form.Group>

                <Form.Group controlId="name">
                  <Form.Label>Ονοματεπώνυμο</Form.Label>
                  <Form.Control ref={register} type="text" name="name" defaultValue={flat.name} required />
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control ref={register} type="email" name="email" defaultValue={flat.email} />
                </Form.Group>

                <Form.Group controlId="surface">
                  <Form.Label>Επιφάνεια</Form.Label>
                  <Form.Control ref={register} type="number" step="0.01" name="surface" defaultValue={flat.surface} required />
                </Form.Group>

                <Form.Group controlId="inactive">
                  <Form.Check ref={register} type="checkbox" name="inactive" label="Κλειστό" defaultChecked={flat.inactive} />
                </Form.Group>
              </MyFormSection>
            </Col>

            <Col sm="12">
              <Button type="submit" variant="dark" disabled={submitting} className="mr-3">
                Ενημέρωση
              </Button>
              {submitting &&
                <Spinner className="mr-3" animation="border" variant="dark" size="sm" />
              }
              <Link to={'/flats'}>Πίσω</Link>
            </Col>
          </Row>
        }

      </Form>
    </MyPage>
  )
}

export default connect(mapStateToProps)(EditFlat)