import React from "react";
import { Switch } from "react-router-dom";
import { routes } from "./routes";
import AppRoute from "./Components/AppRoute/AppRoute";

const Template = () => {
  return (
    <>
      <Switch>
        {Object.keys(routes).map( key => {
          return (
            <AppRoute
              key={key}
              path={routes[key].path}
              component={routes[key].component}
              exact={routes[key].exact}
              private={routes[key].private}
            />
          );
        })}
      </Switch>

      <div className="footer-push"></div>
    </>
  );
};

export default Template;
