import Home from "./Routes/Home/Home";
import Buildings from "./Routes/Buidings/Buildings";
import SignIn from "./Routes/SignIn/SignIn";
import EditBuilding from "./Routes/Buidings/EditBuilding";
import Test from "./Routes/Test/Test";
import Flats from "./Routes/Flats/Flats";
import EditFlat from "./Routes/Flats/EditFlat";
import Publications from "./Routes/Publications/Publications";
import Expenses from "./Routes/Expenses/Expenses";
import Heating from "./Routes/Heating/Heating";
import AccountSettings from "./Routes/AccountSettings/AccountSettings";
import EditPublication from "./Routes/Publications/EditPublication";
import Debts from "./Routes/Debts/Debts";
import ForgotPassword from "./Routes/ForgotPassword/ForgotPassword";


export const routes = {
  
  // Private routes
  home: {
    path: "/",
    component: Home,
    exact: true,
    private: true,
    isMenu: false,
    menuText: 'Αρχική',
    pageTitle: 'Αρχική'
  },
  buildings: {
    path: "/buildings",
    component: Buildings,
    exact: true,
    private: true,
    isMenu: true,
    menuText: 'Πολυκατοικίες',
    pageTitle: 'Οι πολυκατοικίες μου'
  },
  editBuilding: {
    path: "/buildings/edit/:id",
    component: EditBuilding,
    exact: false,
    private: true,
    isMenu: false,
    menuText: '',
    pageTitle: 'Επεξεργασία πολυκατοικίας'
  },
  flats: {
    path: "/flats",
    component: Flats,
    exact: true,
    private: true,
    isMenu: true,
    menuText: 'Διαμερίσματα',
    pageTitle: 'Διαμερίσματα'
  },
  editFlat: {
    path: "/flats/edit/:id",
    component: EditFlat,
    exact: false,
    private: true,
    isMenu: false,
    menuText: '',
    pageTitle: 'Επεξεργασία διαμερίσματος'
  },
  publications: {
    path: "/publications",
    component: Publications,
    exact: true,
    private: true,
    isMenu: true,
    menuText: 'Εκδόσεις',
    pageTitle: 'Εκδόσεις κοινοχρήστων'
  },
  editPublication: {
    path: "/publications/edit/:id",
    component: EditPublication,
    exact: false,
    private: true,
    isMenu: false,
    menuText: '',
    pageTitle: 'Επεξεργασία έκδοσης'
  },
  expenses: {
    path: "/expenses",
    component: Expenses,
    exact: true,
    private: true,
    isMenu: true,
    menuText: 'Δαπάνες',
    pageTitle: 'Δαπάνες'
  },
  heating: {
    path: "/heating",
    component: Heating,
    exact: true,
    private: true,
    isMenu: true,
    menuText: 'Θέρμανση',
    pageTitle: 'Ρυθμίσεις θέρμανσης'
  },
  debts: {
    path: "/debts",
    component: Debts,
    exact: true,
    private: true,
    isMenu: true,
    menuText: 'Οφειλές',
    pageTitle: 'Προηγούμενες οφειλές'
  },
  
  // Miscelanous
  accountSettings: {
    path: "/account-settings",
    component: AccountSettings,
    exact: true,
    private: true,
    isMenu: true,
    menuText: 'Λογαριασμός',
    pageTitle: 'Ρυθμίσεις λογαριασμού'
  },

  // Public routes
  signin: {
    path: "/signin",
    component: SignIn,
    exact: true,
    private: false
  },
  signup: {
    path: "/signup",
    component: SignIn,
    exact: true,
    private: false
  },
  forgotPassword: {
    path: "/forgot-password",
    component: ForgotPassword,
    exact: true,
    private: false
  },

  test: {
    path: '/test',
    component: Test,
    exact: true,
    private: false
  }
}
