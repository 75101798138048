import React from 'react'
import { Card } from 'react-bootstrap'
import SignInLogo from '../../Routes/SignIn/Components/SignInLogo'
import Overlay from '../Overlay/Overlay'
import PropTypes from 'prop-types'


export default function AuthWidget({ disabled, children }) {
  return (
    <Card>
      <SignInLogo />

      { disabled ?
        <Overlay />
        : ''
      }

      <Card.Body className="border-top">
        {children}
      </Card.Body>
    </Card>
  )
}

AuthWidget.propTypes = {
  disabled: PropTypes.bool
}