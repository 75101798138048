import { toast } from 'react-toastify'


export default function useNotify() {


  return {
    toast
  }
}
