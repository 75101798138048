import React from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import MyRow from '../../Components/Layout/MyRow'
import { month } from '../../texts/months'

export default function CreatePublication({ createPublication, submitting }) {

  const { control, handleSubmit, setValue } = useForm()

  const date = new Date()
  const dateMonth = date.getMonth()
  const dateYear = date.getFullYear()

  const suggestionPrevMonth = dateMonth ? month[dateMonth] + ' ' + dateYear : month[12] + ' ' + (dateYear - 1)
  const suggestionNextMonth = dateMonth === 11 ? month[1] + ' ' + (dateYear + 1) : month[dateMonth + 1] + ' ' + dateYear


  return (
    <MyRow>
      <Form onSubmit={handleSubmit(createPublication)}>
        <Form.Group controlId="name">
          <Form.Label>Όνομα έκδοσης</Form.Label>
          <Controller as={Form.Control} control={control} type="text" name="name" defaultValue="" required placeholder="γράψε το όνομα της έκδοσης" />
          <Form.Text>{'π.χ. '}
            <Button variant="light" size="xs" onClick={() => { setValue('name', suggestionPrevMonth) }}>{suggestionPrevMonth}</Button>
            {' ή '}
            <Button variant="light" size="xs" onClick={() => { setValue('name', suggestionNextMonth) }}>{suggestionNextMonth}</Button>
          </Form.Text>
        </Form.Group>

        <Button type="submit" variant="dark" disabled={submitting} className="mr-3">Δημιουργία</Button>
        {submitting &&
          <Spinner className="mr-3" animation="border" variant="dark" size="sm" />
        }
      </Form>
    </MyRow>
  )
}
