import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'


export default function NextStep({ text, route }) {
  return (
    <Alert variant="success">
      <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
        {text}
        <h3 className='m-0' style={{display: 'flex'}}>
          <i className="fal fa-angle-double-right ml-2 mr-2"></i>
        </h3>
        <Button variant='success' as={Link} to={route.path}>{route.menuText}</Button> 
        <div>

        </div>
        {/* <i className="fal fa-forward-step ml-2"></i> */}
        {/* <i className="fa-solid fa-forward-step mr-2"></i> */}
      </div>
    </Alert>
  )
}

NextStep.defaultProps = {
  text: 'Προχώρησε στο επόμενο βήμα',
}

NextStep.propTypes = {
  text: PropTypes.string,
  route: PropTypes.object.isRequired,
}