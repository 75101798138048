import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import BlockListItem from '../../Components/BlockListItem/BlockListItem'
import BlockListNewItem from '../../Components/BlockListNewItem/BlockListNewItem'
import MyPage from '../../Components/Layout/MyPage'
import { MainLoader } from '../../Components/Loader/Loader'
import NextStep from '../../Components/NextStep/NextStep'
import useUserFirestore from '../../hooks/useUserFirestore'
import { routes } from '../../routes'
import CreateBuilding from './CreateBuilding'

const mapStateToProps = state => ({
    selectedBuilding: state.global.selectedBuilding,
    buildings: state.firestore.ordered.buildings
})

function Buildings({ selectedBuilding, buildings }) {
  const [submitting, setSubmitting] = useState(false)
  const [creatingBuilding, setCreatingBuilding] = useState(false)
  const userFirestore = useUserFirestore()
  const dispatch = useDispatch()

  const setDefaultBuilding = (id) => {
    dispatch({
      type: "CLEAR_PUBLICATION"
    })
    dispatch({
      type: "SET_BUILDING",
      payload: id
    })
  }

  const createBuilding = (e) => {
    e.preventDefault()

    setSubmitting(true)
    userFirestore
      .collection('buildings')
      .add({
        name: e.target.elements.createBuildingName.value,
        createdAt: Date.now()
      })
      .then(docRef => {
        setDefaultBuilding(docRef.id)
        setSubmitting(false)
        setCreatingBuilding(false)
      })
      .catch((err) => {
        setSubmitting(false)
        alert(err)
      })
  }

  return (
    <MyPage title="Οι πολυκατοικίες μου">

      {selectedBuilding && buildings ?
        <div className="mb-3">
          <NextStep route={routes.publications} />
        </div> :
        <div className="mb-3">
          <Alert variant="warning">
            Επίλεξε μια υπάρχουσα πολυκατοικία, ή πρόσθεσε μια νέα για να συνεχίσεις.
          </Alert>
        </div>
      }

      <MainLoader loading={!isLoaded(buildings)} />

      {buildings && buildings.map((building, key) => {
        return (
          <BlockListItem linkUrl="#" key={key} id={building.id} linkOnClick={setDefaultBuilding} selected={selectedBuilding === building.id} >
            {building.name}
            <Link to={`/buildings/edit/${building.id}`} onClick={(e) => e.stopPropagation()} className="edit-button">
              <i className="fas fa-pencil-alt"></i>
            </Link>
          </BlockListItem>
        )
      })}

      {isLoaded(buildings) && isEmpty(buildings) &&
        <div className="mb-3">Δεν υπάρχουν καταχωρημένες πολυκατοικίες.</div>
      }

      {creatingBuilding ?
        <CreateBuilding createBuilding={createBuilding} submitting={submitting} setCreatingBuilding={setCreatingBuilding} />
        :
        <BlockListNewItem text="Πρόσθεσε μια νέα πολυκατοικία" setCreating={setCreatingBuilding} />
      }

    </MyPage>
  )
}

export default connect(mapStateToProps)(Buildings)
