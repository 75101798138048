import React, { useState } from 'react'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useFirebase } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import AuthView from '../../Components/Layout/AuthView'
import AuthWidget from '../../Components/Layout/AuthWidget'
import { routes } from '../../routes'

export default function ForgotPassword() {
  const [submitting, setSubmitting] = useState(false)
  const [message, setMessage] = useState(null)
  const { control, handleSubmit, reset } = useForm()
  const firebase = useFirebase()

  const resetPassword = ({ passwordResetEmail }) => {
    setSubmitting(true)

    console.log('passwordResetEmail', passwordResetEmail)

    firebase
      .auth()
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        setSubmitting(false)
        reset()
        setMessage(`Εάν υπάρχει λογαριασμός με το email ${passwordResetEmail}, θα λάβεις σύντομα ένα μήνυμα με οδηγίες για επαναφορά του κωδικού σου.`)
      })
      .catch((err) => {
        setSubmitting(false)
        console.log('err', err)
      })
  }

  const showError = () => {
    if (message) {
      return (
        <Alert variant="success">
          {message}
        </Alert>
      )
    }
    return <></>
  }

  return (
    <AuthView>
      <AuthWidget disabled={submitting}>

        {showError()}

        <Form onSubmit={handleSubmit(resetPassword)}>

          <Form.Group>
            <Controller as={Form.Control} name='passwordResetEmail' control={control} defaultValue="" placeholder='Email' type='email' />
          </Form.Group>

          <Form.Group>
            <Button block variant='dark' type="submit">
              {submitting ?
                <Spinner animation="border" variant="light" size="sm" />
                :
                <>
                  <i className='fal fa-redo' style={{ marginRight: 10 }}></i>
                  Επαναφορά κωδικού
                </>
              }
            </Button>
          </Form.Group>

          <Form.Text className="text-muted">
            <em><Link to={routes.signin.path}>Νομίζω πως τον θυμήθηκα</Link></em>
          </Form.Text>

        </Form>
      </AuthWidget>
    </AuthView>
  )
}
