import React, { useState, useEffect } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link, useParams } from "react-router-dom"
import useUserFirestore from '../../hooks/useUserFirestore'
import MyFormSection from '../../Components/Layout/MyFormSection'
import MyPage from '../../Components/Layout/MyPage'
import { connect } from 'react-redux'
import useNotify from '../../hooks/useNotify'
import lodash from "lodash";


/**
 * Mapping state to props to get access to buildings data from the Redux store.
 */
const mapStateToProps = state => ({
  buildings: state.firestore.data.buildings
})


/**
 * A React functional component for editing building details.
 *
 * @param {object} props The properties object, it contains buildings data.
 */
function EditBuilding({ buildings }) {
  const [submitting, setSubmitting] = useState(false)
  const [initialState, setInitialState] = useState(null)
  const [isDirty, setIsDirty] = useState(false)
  const [building, setBuilding] = useState(null)
  const { register, handleSubmit, watch } = useForm()
  
  const { id } = useParams()
  const firestore = useUserFirestore()
  const { toast } = useNotify()
  const formValues = watch()


  /**
   * UseEffect for handling the change in form data.
   */
  useEffect(() => {
    if (building && !initialState) {
      setInitialState(building)
    }

    if (initialState) {
      // setIsDirty(JSON.stringify(formValues) !== JSON.stringify(initialState))
      setIsDirty(!lodash.isEqual(formValues, initialState))
    }
  }, [building, initialState, formValues, isDirty])


  useEffect(() => {
    if (buildings) {
      setBuilding((({ createdAt, ...o }) => o)(buildings[id]))
    }
  }, [buildings, id])
  

  /**
   * Function to update building details in Firestore.
   *
   * @param {object} form The form data.
   * @param {object} e The event object.
   */
  const updateBuilding = (form, e) => {
    setSubmitting(true)
    firestore
      .collection('buildings')
      .doc(id)
      .update(form)
      .then(() => {
        setInitialState(form)
        setSubmitting(false)
        toast.success('Επιτυχής ενημέρωση', {
          toastId: "editBuildingSuccess"
        })
      })
      .catch(err => {
        setSubmitting(false)
        toast.error('Κάτι πήγε στραβά', {
          toastId: 'editBuildingError'
        })
      })
  }

  /**
   * Rendering the component.
   */
  return (
    <MyPage title="Επεξεργασία πολυκατοικίας">
      <Form onSubmit={handleSubmit(updateBuilding)}>

        {building &&
          <Row>
            <Col sm="12">
              <MyFormSection headerText="Γενικά στοχεία">
                <Form.Group>
                  <Form.Label>Ονομασία πολυκατοικίας</Form.Label>
                  <Form.Control type="text" name="name" ref={register} defaultValue={building.name} />
                </Form.Group>
              </MyFormSection>
            </Col>

            <Col md="12" lg="6">
              <MyFormSection headerText="Στοιχεία κτηρίου">
                <Form.Group>
                  <Form.Label>Διεύθυνση πολυκατοικίας</Form.Label>
                  <Form.Control type="text" name="address" ref={register} defaultValue={building.address} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Περιοχή</Form.Label>
                  <Form.Control type="text" name="city" ref={register} defaultValue={building.city} />
                </Form.Group>
              </MyFormSection>

              <MyFormSection headerText="Στοιχεία Διαχειριστή">
                <Form.Group>
                  <Form.Label>Όνομα διαχειριστή</Form.Label>
                  <Form.Control type="text" name="directorName" ref={register} defaultValue={building.directorName} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Τηλέφωνο διαχειριστή</Form.Label>
                  <Form.Control type="tel" name="directorPhone" ref={register} defaultValue={building.directorPhone} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email διαχειριστή</Form.Label>
                  <Form.Control type="email" name="directorEmail" ref={register} defaultValue={building.directorEmail} />
                </Form.Group>
              </MyFormSection>
            </Col>

            <Col md="12" lg="6">
              <MyFormSection headerText="Θέρμανση">
                <Form.Group>
                  <Form.Label>Είδος Θέρμανσης</Form.Label>
                  <Form.Control as="select" name="heatingType" ref={register} defaultValue={building.heatingType}>
                    <option value="">Επιλέξτε είδος θέρμανσης</option>
                    <optgroup label="Κεντρική Θέρμανση">
                      <option value="central">Κεντρική Θέρμανση με χιλιοστά</option>
                    </optgroup>
                    <optgroup label="Αυτόνομη">
                      <option value="autonomousHoursEiFi">Αυτόνομη Θέρμανση με ώρες και μελέτη (ei, fi)</option>
                      <option value="autonomousHoursSurface">Αυτόνομη Θέρμανση με ώρες και χιλιοστά</option>
                      <option value="autonomousCaloriesEiFi">Αυτόνομη Θέρμανση με θερμίδες και μελέτη (ei, fi)</option>
                    </optgroup>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Πάγιο Θέρμανσης (%)</Form.Label>
                  <Form.Control type="number" step="0.01" name="heatingFixed" ref={register} defaultValue={building.heatingFixed || 30} min={0} max={100} />
                </Form.Group>
              </MyFormSection>

              <MyFormSection headerText="Άλλες ρυθμίσεις">
                <Form.Group>
                  <Form.Label>Συμμετοχή κλειστών διαμερισμάτων (%)</Form.Label>
                  <Form.Control type="number" step="0.01" name="emptyParticipation" ref={register} defaultValue={building.emptyParticipation || 100} max={100} min={0} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Ανακοίνωση</Form.Label>
                  <Form.Control as="textarea" rows="2" name="announcement" ref={register} defaultValue={building.announcement} />
                </Form.Group>
              </MyFormSection>
            </Col>

            <Col sm="12">
              <Button type="submit" variant="dark" disabled={submitting || !isDirty} className="mr-3">
                Ενημέρωση
              </Button>
              {submitting &&
                <Spinner className="mr-3" animation="border" variant="dark" size="sm" />
              }
              <Link to="/buildings">Ακύρωση</Link>
            </Col>
          </Row>

        }
      </Form>
    </MyPage>
  )
}


export default connect(mapStateToProps)(EditBuilding)