import React, { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link, useParams } from "react-router-dom"
import useUserFirestore from '../../hooks/useUserFirestore'
import { connect } from 'react-redux'
import MyFormSection from '../../Components/Layout/MyFormSection'
import MyPage from '../../Components/Layout/MyPage'
import useNotify from '../../hooks/useNotify'

const mapStateToProps = (state) => ({
  selectedBuilding: state.global.selectedBuilding,
  publications: state.firestore.data.publications
})

function EditPublication({ selectedBuilding, publications }) {
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit } = useForm()
  const firestore = useUserFirestore()
  const { id } = useParams()
  const publication = publications ? publications[id] : null

  const { toast } = useNotify()

  const updatePublication = (form, e) => {
    setSubmitting(true)
    firestore
      .collection('buildings')
      .doc(selectedBuilding)
      .collection('publications')
      .doc(id)
      .update(form)
      .then(() => {
        setSubmitting(false)
        toast.success('Επιτυχής ενημέρωση', {
          toastId: "editPublicationSuccess"
        })
      })
      .catch(err => {
        setSubmitting(false)
        toast.error('Κάτι πήγε στραβά', {
          toastId: 'editPublicationError'
        })
      })
  }

  return (
    <MyPage title="Επεξεργασία έκδοσης">
      <Form onSubmit={handleSubmit(updatePublication)}>
        {publication &&
          <Row>
            <Col>
              <MyFormSection headerText="Γενικά στοιχεία">
                <Form.Group controlId="number">
                  <Form.Label>Όνομα έκδοσης</Form.Label>
                  <Form.Control ref={register} type="text" name="name" defaultValue={publication.name} required />
                </Form.Group>
              </MyFormSection>
            </Col>

            <Col sm="12">
              <Button type="submit" variant="dark" disabled={submitting} className="mr-3">
                Ενημέρωση
              </Button>
              {submitting &&
                <Spinner className="mr-3" animation="border" variant="dark" size="sm" />
              }
              <Link to="/publications">Ακύρωση</Link>
            </Col>
          </Row>
        }
      </Form>
    </MyPage>
  )
}

export default connect(mapStateToProps)(EditPublication)
