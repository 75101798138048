const defaultStracture = ['', 'Περιγραφή', 'Ποσό']

export const expensesStracture = {
  commonArea: {
    title: "A - Κοινόχρηστα",
    head: defaultStracture,
    rows: 6
  },
  heating: {
    title: "Β - Θέρμανση",
    head: defaultStracture,
    rows: 4
  },
  elevator: {
    title: "Γ - Ανελκυστήρας",
    head: defaultStracture,
    rows: 4
  },
  owners: {
    title: "Δ - Ιδιοκτητών",
    head: defaultStracture,
    rows: 3
  },
  special1: {
    title: "Ε - Ειδικές δαπάνες 1",
    head: defaultStracture,
    rows: 3
  },
  special2: {
    title: "Ζ - Ειδικές δαπάνες 2",
    head: defaultStracture,
    rows: 3
  },
}