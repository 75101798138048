import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import MyFormSection from '../../Components/Layout/MyFormSection'
import MyPage from '../../Components/Layout/MyPage'
import useUserFirestore from '../../hooks/useUserFirestore'
import { expensesStracture } from './ExpensesStracture'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import useNotify from '../../hooks/useNotify'


const mapStateToProps = state => {
  return {
    publications: state.firestore.data.publications,
    selectedBuilding: state.global.selectedBuilding,
    selectedPublication: state.global.selectedPublication,
    uid: state.firebase.auth.uid
  }
}

const Expenses = ({publications, selectedBuilding, selectedPublication}) => {
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit, setValue } = useForm()
  const firestore = useUserFirestore()
  const { toast } = useNotify()

  let publication = null
  publication = publications && publications[selectedPublication]

  const updateExpenses = (expenses, e) => {
    setSubmitting(true)
    firestore
      .collection('buildings')
      .doc(selectedBuilding)
      .collection('publications')
      .doc(selectedPublication)
      .update({
        expenses
      })
      .then(() => {
        setSubmitting(false)
        toast.success('Επιτυχής αποθήκευση', {
          toastId: "editExpensesSuccess"
        })
      })
      .catch(err => {
        setSubmitting(false)
        toast.error('Κάτι πήγε στραβά', {
          toastId: 'editExpensesError'
        })
      })
  }

  // Updates values in form
  const updateValues = useCallback(() => {
    Object.keys(expensesStracture).map(key => {
      [...Array(expensesStracture[key].rows)].map((val, subKey) => {
        setValue(`${key}.${subKey}.description`, publication && publication.expenses ? publication.expenses[key][subKey].description : '')
        setValue(`${key}.${subKey}.amount`, publication && publication.expenses ? publication.expenses[key][subKey].amount : '')
        return true
      })
      return true
    })
  }, [publication, setValue])

  useEffect(() => {
    updateValues()
  }, [updateValues, publications])

  if (selectedBuilding && selectedPublication) {

    return (
      <MyPage title="Δαπάνες">
        <Form onSubmit={handleSubmit(updateExpenses)}>
          <Row>

            {Object.keys(expensesStracture).map(key => {
              return (
                <Col md="12" lg="6" key={key}>
                  <MyFormSection headerText={expensesStracture[key].title}>
                    <Table variant="light" className="d-form-table">
                      <thead>
                        <tr>
                          {expensesStracture[key].head.map((val, subKey) => {
                            return (
                              <th key={subKey}>{val}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {[...Array(expensesStracture[key].rows)].map((val, subKey) => {
                          return (
                            <tr key={subKey}>
                              <td>{subKey + 1}</td>
                              <td>
                                <Form.Control
                                  type="text"
                                  ref={register}
                                  name={`${key}.${subKey}.description`}
                                  defaultValue={publication && publication.expenses ? publication.expenses[key][subKey].description : ''}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="number"
                                  step="0.01"
                                  ref={register}
                                  name={`${key}.${subKey}.amount`}
                                  defaultValue={publication && publication.expenses ? publication.expenses[key][subKey].amount : ''}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </MyFormSection>
                </Col>
              )
            })}

            <Col sm="12">
              <Button type="submit" variant="dark" disabled={submitting} className="mr-3">
                Αποθήκευση
              </Button>
              {submitting &&
                <Spinner className="mr-3" animation="border" variant="dark" size="sm" />
              }
            </Col>

          </Row>
        </Form>
      </MyPage>
    )
  } else if (!selectedBuilding) {
    return (
      <Redirect
        to={{
          pathname: "/buildings",
          state: { from: "expenses" }
        }}
      />
    )
  } else {
    <Redirect
      to={{
        pathname: "/publications",
        state: { from: "expenses" }
      }}
    />
  }
}

export default connect(mapStateToProps)(Expenses)