import React from 'react'
import './MyPageTitle.css'

export default function MyPageTitle({children}) {
  return (
    <>
    <h4 className="d-page-title">
      <i className="fas fa-angle-right small mr-3 d-page-title-icon"></i>
      {children}
    </h4>
    </>
  )
}
