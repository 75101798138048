import React from 'react'

const settings = {
  bootstrapMargin: "3" ,
  widthPercentage: 100,
  borderOpacity: 0
}

export default function SidebarDivider() {
  return (
    <div className={`mt-${settings.bootstrapMargin} mb-${settings.bootstrapMargin}`} style={{
      borderBottom: `1px solid rgba(255,255,255,${settings.borderOpacity})`,
      width: `${settings.widthPercentage}%`,
      marginLeft: settings.widthPercentage < 100 ? "23px" : 0
    }}>
      
    </div>
  )
}
