import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MyFormSection from '../../Components/Layout/MyFormSection'
import MyPage from "../../Components/Layout/MyPage"
import useNotify from '../../hooks/useNotify'
import useUserFirestore from '../../hooks/useUserFirestore'


const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firestore.data.profile
})

function AccountSettings({ auth, profile }) {
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit, setValue } = useForm()
  const firestore = useUserFirestore()
  const { toast } = useNotify()

  const displayName = profile ? profile.displayName : ''

  const updateProfile = (form, e) => {
    setSubmitting(true)

    firestore
      .update({
        displayName: form.displayName
      })
      .then(() => {
        setSubmitting(false)
        toast.success('Επιτυχής ενημέρωση', {
          toastId: "editAccountSettingsSuccess"
        })
      })
      .catch(err => {
        setSubmitting(false)
        toast.error('Κάτι πήγε στραβά', {
          toastId: 'editAccountSettingsError'
        })
      })
  }

  const updateValues = useCallback(() => {
    if (displayName) {
      setValue(`displayName`, displayName)
    }
  }, [displayName, setValue])

  useEffect(() => {
    updateValues()
  }, [updateValues, displayName])

  return (
    <MyPage title="Ρυθμίσεις λογαριασμού">

      <Card className="mb-5">
        <Card.Body>
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <h1>
              <i className="fad fa-stars text-primary mr-3"></i>
            </h1>
            <h5>
              Αναβάθμισε τώρα σε Premium
            </h5>
          </div>
          <div className="mt-3">
            Απέκτησε δημιουργία πολλαπλών οικοδομών, ιστορικό 36 εκδόσεων και πολλά άλλα!<br/>
            <Link className='small' to={"#"}>Διάβασε περισσότερα</Link>
          </div>
          <Button variant="primary" className="mt-3">Αναβάθμιση τώρα</Button>
        </Card.Body>
      </Card>

      <Form onSubmit={handleSubmit(updateProfile)}>
        <MyFormSection headerText="Προφίλ">
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <div className="pl-2">{auth.email}</div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Όνομα</Form.Label>
            <Form.Control type="text" name="displayName" ref={register} defaultValue={displayName} />
          </Form.Group>
        </MyFormSection>
        <Button type="submit" variant="dark" disabled={submitting} className="mr-3">
          Ενημέρωση
        </Button>
        {submitting &&
          <Spinner className="mr-3" animation="border" variant="dark" size="sm" />
        }
      </Form>

    </MyPage>
  )
}


export default connect(mapStateToProps)(AccountSettings)