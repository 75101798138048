import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import { createStore } from "redux"
import { Provider } from "react-redux"
import { ReactReduxFirebaseProvider } from "react-redux-firebase"
import { createFirestoreInstance } from "redux-firestore"
import { rootReducer } from "./ducks/reducers"
import { BrowserRouter } from "react-router-dom"

import App from "./App"
import { firebaseConfig } from "./config/firebase"
import { rrfConfig } from "./config/rrf"

// Imports bootstrap scss along with our custom variables
import "./styles/dapani-bootstrap.scss"
// Global css
import "./styles/index.css"
import "./styles/responsive.css"
import "./styles/d-block-list.scss"
import "./styles/d-form-table.scss"

firebase.initializeApp(firebaseConfig);
firebase.firestore()

const useEmulators = false

// Remove or comment out the lines below when not using emulator auth
if (window.location.hostname === "localhost" && useEmulators) {
  console.log("USING EMULATORS");
  firebase.firestore().useEmulator(window.location.hostname, 8080);
  firebase.auth().useEmulator(`http://${window.location.hostname}:9099/`);
}

const initialState = {};
const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance //since we are using Firestore
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();