import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

export default function BlockListNewItem({text, setCreating}) {
  return (
    <div>
      <Link to="#" onClick={() => setCreating(true)}>
        <i className="fal fa-plus-square mr-2"></i>
        {text}
      </Link>
    </div>
  )
}

BlockListNewItem.defaultProps = {
  text: "Enter text here"
}

BlockListNewItem.propTypes = {
  text: PropTypes.string.isRequired,
  setCreating: PropTypes.func.isRequired
}