// Constants
const SET_BUILDING = "SET_BUILDING"
const CLEAR_BUILDING = "CLEAR_BUILDING"
const SET_PUBLICATION = "SET_PUBLICATION"
const CLEAR_PUBLICATION = "CLEAR_PUBLICATION"
const TOGGLE_MENU = "TOGGLE_MENU"
const CLOSE_MENU = "CLOSE_MENU"

// Initial state
const initialGlobalState = {
  selectedBuilding: null,
  selectedPublication: null,
  menuExpanded: false
}

// Actions
export function setBuilding(payload) {
  return {type: SET_BUILDING, payload}
}
export function clearBuilding() {
  return {type: CLEAR_BUILDING}
}

export function setPublication(payload) {
  return {type: SET_PUBLICATION, payload}
}
export function clearPublication() {
  return {type: CLEAR_PUBLICATION }
}
export function toggleMenu() {
  return {type: TOGGLE_MENU}
}

export function closeMenu() {
  return {type: CLOSE_MENU}
}

// Reducer
export function globalReducer(state = initialGlobalState, action) {
  if (action.type === SET_BUILDING) {
    return Object.assign({}, state, {
      selectedBuilding: action.payload
    })
  }
  if (action.type === CLEAR_BUILDING) {
    return Object.assign({}, state, {
      selectedBuilding: null
    })
  }
  if (action.type === SET_PUBLICATION) {
    return Object.assign({}, state, {
      selectedPublication: action.payload
    })
  }
  if (action.type === CLEAR_PUBLICATION) {
    return Object.assign({}, state, {
      selectedPublication: null
    })
  }
  if (action.type === TOGGLE_MENU) {
    return Object.assign({}, state, {
      menuExpanded: !state.menuExpanded
    })
  }
  if (action.type === CLOSE_MENU) {
    return Object.assign({}, state, {
      menuExpanded: false
    })
  }

  return state
}