import React, { useRef, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import MyRow from '../../Components/Layout/MyRow'
import useUserFirestore from '../../hooks/useUserFirestore'

export default function CreateFlat({ submitting, setSubmitting, creating, setCreating, selectedBuilding }) {
  const [keepFormOpen, setKeepFormOpen] = useState(false)
  const { register, control, handleSubmit, reset } = useForm()
  const firstInputRef = useRef();
  
  const userFirestore = useUserFirestore()

  const handleSwitch = (e) => {
    setKeepFormOpen(e.target.checked)
  }

  const createFlat = (form) => {
    setSubmitting(true)
    // console.log('form', form)
    
    userFirestore
      .collection('buildings')
      .doc(selectedBuilding)
      .collection('flats')
      .add({
        ...form,
        createdAt: Date.now()
      })
      .then(() => {
        setSubmitting(false)
        if (keepFormOpen) {
          reset()
          firstInputRef.current.focus()
        } else {
          setCreating(false)
        }
      })
      .catch((err) => {
        setSubmitting(false)
        alert(err)
      })
  }

  return (
    <MyRow>
        <Form.Group controlId="keepOpen" >
          <Form.Check type="checkbox" label="Κράτησε αυτή τη φόρμα ανοιχτή (για πολλαπλή καταχώρηση διαμερισμάτων)" name="keepOpen" checked={keepFormOpen} onChange={handleSwitch} />
        </Form.Group>
        
        <Form onSubmit={handleSubmit(createFlat)}>


        <Form.Group controlId="number">
          <Form.Label>Αρίθμηση</Form.Label>
          {/* <Controller as={Form.Control} control={control} type="text" name="number" defaultValue="" placeholder="π.χ. Α1" required autoFocus ref={(e) => firstInputRef.current = e} /> */}
          <Form.Control type="text" name="number" defaultValue="" placeholder="π.χ. Α1" required autoFocus ref={(e) => {register(e); firstInputRef.current = e}} />
        </Form.Group>

        <Form.Group controlId="name">
          <Form.Label>Ονοματεπώνυμο</Form.Label>
          <Controller as={Form.Control} control={control} type="text" name="name" defaultValue="" placeholder="π.χ. Γιώργος Οικονόμου" required />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Controller as={Form.Control} control={control} type="email" name="email" defaultValue="" placeholder="name@example.com" />
        </Form.Group>

        <Form.Group controlId="surface">
          <Form.Label>Επιφάνεια</Form.Label>
          <Controller as={Form.Control} control={control} type="number" name="surface" defaultValue="" placeholder="τμ" required />
        </Form.Group>

        <Form.Group controlId="inactive">
          <Form.Check ref={register} type="checkbox" label="Κλειστό" name="inactive" />
        </Form.Group>

        <Button type="submit" variant="dark" disabled={submitting} className="mr-3">Προσθήκη</Button>
        {submitting &&
          <Spinner className="mr-3" animation="border" variant="primary" size="sm" />
        }
        <Link to="#" onClick={() => setCreating(false)}>Ακύρωση</Link>
      </Form>
    </MyRow>
  )
}
