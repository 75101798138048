import React from 'react'
import { connect } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

const mapStateToProps = state => ({
  expanded: state.global.menuExpanded,
  selectedBuilding: state.global.selectedBuilding,
  selectedPublication: state.global.selectedPublication,
  buildings: state.firestore.data.buildings,
  flats: state.firestore.data.flats,
  publications: state.firestore.data.publications,
  uid: state.firebase.auth.uid,
  profile: state.firestore.data.profile
})

function FirestoreConnect({ children, uid, selectedBuilding }) {
  useFirestoreConnect(
    [
      {
        collection: `/users/${uid}/buildings`,
        orderBy: ['createdAt', 'asc'],
        storeAs: 'buildings',
      },
      {
        collection: `/users/${uid}/buildings/${selectedBuilding}/flats`,
        orderBy: ['createdAt', 'asc'],
        storeAs: 'flats'
      },
      {
        collection: `/users/${uid}/buildings/${selectedBuilding}/publications`,
        orderBy: ['createdAt', 'desc'],
        storeAs: 'publications'
      },
      {
        collection: 'users',
        doc: `${uid}`,
        storeAs: 'profile'
      }
    ]
  )
  return (
    <>
      {children}
    </>
  )
}

export default connect(mapStateToProps)(FirestoreConnect)