import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PropTypes from "prop-types"

export default function BlockListItem({ id, cardClassName, linkUrl = '', linkOnClick = null, selected = false, children }) {

  if (linkUrl) {
    return (
      <Link to={linkUrl} onClick={() => linkOnClick(id)} className="d-block-list-link">
        <Card className={`row-card ${selected && 'selected'}`}>
          {children}
        </Card>
      </Link>
    )
  }
  return (
    <Card className={cardClassName}>
      {children}
    </Card>
  )
}

BlockListItem.propTypes = {
  id: PropTypes.string,
  cardClassName: PropTypes.string,
  linkUrl: PropTypes.string,
  linkOnClick: PropTypes.func,
  selected: PropTypes.bool,
}

