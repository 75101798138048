import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MyFormSection from '../../Components/Layout/MyFormSection'
import MyPage from '../../Components/Layout/MyPage'
import useNotify from '../../hooks/useNotify'
import useUserFirestore from '../../hooks/useUserFirestore'
import { routes } from '../../routes'

const mapStateToProps = (state) => ({
  selectedBuilding: state.global.selectedBuilding,
  selectedPublication: state.global.selectedPublication,
  flats: state.firestore.data.flats,
  publications: state.firestore.data.publications,
  uid: state.firebase.auth.uid
})

const Debts = ({ selectedBuilding, selectedPublication, publications, flats }) => {
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit, setValue } = useForm()
  const firestore = useUserFirestore()
  const { toast } = useNotify()

  let publication = null
  publication = publications && publications[selectedPublication]

  const updateDebts = (debts, e) => {
    setSubmitting(true)
    firestore
      .collection('buildings')
      .doc(selectedBuilding)
      .collection('publications')
      .doc(selectedPublication)
      .update({
        debts
      })
      .then(() => {
        setSubmitting(false)
        toast.success('Επιτυχής αποθήκευση', {
          toastId: "editDebtsSuccess"
        })
      })
      .catch(err => {
        setSubmitting(false)
        toast.error('Κάτι πήγε στραβά', {
          toastId: 'editDebtsError'
        })
      })
  }

  const updateValues = useCallback(() => {
    if (flats && publication) {
      Object.keys(flats).map(key => {
        setValue(`${key}.amount`, publication && publication.debts ? publication.debts[key].amount : '')
        return true
      })
    }
  }, [flats, publication, setValue])

  useEffect(() => {
    updateValues()
  }, [updateValues, publications])

  return (
    <MyPage title={routes.debts.pageTitle}>
      <Form onSubmit={handleSubmit(updateDebts)}>
        <Row>
          <Col xs="12">
            {flats ?
              <>
                <MyFormSection>
                  <Table variant="light" className="d-form-table">
                    <thead>
                      <tr>
                        <th>
                          Αριθμός
                    </th>
                        <th>
                          Όνομα
                    </th>
                        <th>
                          Ποσό
                    </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(flats).map((key) => {
                        return (
                          <tr key={key}>
                            <td>{flats[key].number}</td>
                            <td>{flats[key].name}</td>
                            <td>
                              <Form.Control
                                type="number"
                                step="0.01"
                                ref={register}
                                name={`${key}.amount`}
                                defaultValue={publication && publication.debts ? publication.debts[key].amount : ''}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </MyFormSection>
                <Button type="submit" variant="dark" disabled={submitting} className="mr-3">
                  Αποθήκευση
                </Button>
                {submitting &&
                  <Spinner className="mr-3" animation="border" variant="dark" size="sm" />
                }
              </> :

              <>
                <div className="mb-3">
                  <Link to={routes.flats.path}>Πρόσθεσε διαμερίσματα</Link> στην πολυκατοικία για να εισάγεις οφειλές.
                </div>
                <div>
                  <Button as={Link} to={routes.flats.path} variant='dark'>Διαμερίσματα</Button>
                </div>
              </>
            }
          </Col>
        </Row>
      </Form>
    </MyPage>
  )
}

export default connect(mapStateToProps)(Debts)