export const month = {
  1: "Ιανουάριος",
  2: "Φεβρουάριος",
  3: "Μάρτιος",
  4: "Απρίλιος",
  5: "Μάιος",
  6: "Ιούνιος",
  7: "Ιούλιος",
  8: "Αύγουστος",
  9: "Σεπτέμβριος",
  10: "Οκτώβριος",
  11: "Νοέμβριος",
  12: "Δεκέμβριος"
}