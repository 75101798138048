import React from 'react'
import { useDispatch } from 'react-redux'
import TopbarLogo from './TopbarLogo'
import TopbarMenuButton from './TopbarMenuButton'
import './Topbar.css'

export default function TopBar() {
  const dispatch = useDispatch()

  const toggleMenu = () => {
    dispatch({type: "TOGGLE_MENU"})
  }

  return (
    <div id="topbar">
      <TopbarMenuButton toggle={toggleMenu} expanded="menuExpanded" />
      <TopbarLogo />
    </div>
  )
}
