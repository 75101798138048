import React from 'react'
import { useForm, Controller } from "react-hook-form"
import { Card, Form, Button, Spinner } from 'react-bootstrap'
import { useFirebase } from 'react-redux-firebase'
import { Link, useHistory } from 'react-router-dom'
import { routes } from '../../../routes'
import useFirebaseError from '../../../hooks/useFirebaseError'

export default function SignUpForm({ handleSignIn, handleError, loggingIn, error, userHomePath }) {
  const { control, handleSubmit } = useForm()
  const firebase = useFirebase()
  const history = useHistory()
  const { getErrorText } = useFirebaseError()


  const createNewUser = ({ displayName, signUpEmail, signUpPassword1, signUpPassword2 }) => {
    if (signUpPassword1 && signUpPassword1 === signUpPassword2) {
      handleSignIn('email', true)

      firebase
        .createUser(
          {
            email: signUpEmail,
            password: signUpPassword1
          },
          {
            displayName: displayName
          }
        )
        .then((userData) => {
          handleSignIn('email', false)
          history.push(userHomePath)
        })
        .catch((err) => {
          handleSignIn('email', false)
          handleError('email', getErrorText(err.code))
          console.log('error code', err.code)
        })
    } else {
      handleError('email', 'The two passwords does not match')
    }
  }

  return (
    <form onSubmit={handleSubmit(createNewUser)}>
      <Card.Text>
        <Controller as={Form.Control} name='displayName' control={control} defaultValue="" placeholder='Όνομα' type='text' />
      </Card.Text>
      <Card.Text>
        <Controller as={Form.Control} name='signUpEmail' control={control} defaultValue="" placeholder='Email' type='email' required />
      </Card.Text>
      <Card.Text>
        <Controller as={Form.Control} name='signUpPassword1' control={control} defaultValue="" placeholder='Κωδικός' type='password' required />
      </Card.Text>
      <Card.Text>
        <Controller as={Form.Control} name='signUpPassword2' control={control} defaultValue="" placeholder='Επαλήθευση κωδικού' type='password' required />
      </Card.Text>
      <Card.Text>
        <small className='text-danger'>{error}</small>
      </Card.Text>
      <Card.Text>
        <Button block variant='dark' type="submit">
          {loggingIn ?
            <Spinner animation="border" variant="light" size="sm" />
            :
            <>
              <i className='fas fa-envelope' style={{ marginRight: 10 }}></i>
              Δημιουργία λογαριασμού
            </>
          }
        </Button>
      </Card.Text>
      <Card.Text>
        <small className="text-muted"><em>Έχεις ήδη λογαριασμό; <Link to={routes.signin.path}>Είσοδος</Link></em></small>
      </Card.Text>
    </form>
  )
}