import React from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import MyRow from '../../Components/Layout/MyRow'

export default function CreateBuilding({createBuilding, submitting, setCreatingBuilding}) {
  const [value, setValue] = React.useState(null)
  
  return (
    <MyRow>
      <Form onSubmit={createBuilding}> 
        <Form.Group controlId="createBuildingName">
          <Form.Label>Ονομασία πολυκατοικίας</Form.Label>
          <Form.Control type="text" name="createBuildingName" onChange={e => setValue(e.target.value) } placeholder="π.χ. διεύθυνση" required />
        </Form.Group>
        <Button type="submit" variant="dark" disabled={submitting || !value} className="mr-3">Προσθήκη</Button>
        {submitting &&
          <Spinner className="mr-3" animation="border" variant="dark" size="sm" />
        }
        <Link onClick={() => setCreatingBuilding(false)}>Ακύρωση</Link>
      </Form>
    </MyRow>
  )
}
