import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'


export default function SidebarItem({ path, text, exact, disabled, selected, icon }) {
  const dispatch = useDispatch()

  const closeMenu = () => {
    dispatch({ type: "CLOSE_MENU" })
  }

  if (!disabled) {
    return (
      <NavLink onClick={closeMenu} exact={exact} disabled={disabled} className="d-sidebar-item d-nav-item" to={path} activeClassName="active">
        {icon &&
          <i className={icon + " mr-2"}></i>
        }
        {text}
        {selected &&
          <div className="text-muted small">- {selected}</div>
        }
      </NavLink>
    )
  } else {
    return (
      <div className="d-sidebar-item d-nav-item disabled">
        {icon &&
          <i className={icon + " mr-2"}></i>
        }
        {text}
      </div>
    )
  }
}

SidebarItem.defaultProps = {
  path: '/',
  text: 'Menu item',
  exact: false,
  disabled: false,
  selected: '',
  icon: ''
}

SidebarItem.propTypes = {
  path: PropTypes.string,
  text: PropTypes.string,
  exact: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.string,
  icon: PropTypes.string
}
