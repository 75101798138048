import React from "react"
import { Route } from "react-router-dom"
import PrivateRoute from "../PrivateRoute"
import PropTypes from "prop-types"

export default function AppRoute(props) {
  if (props.private) {
    return (
      <PrivateRoute path={props.path} exact={props.exact}>
        <props.component />
      </PrivateRoute>
    );
  } else {
    return (
      <Route
        path={props.path}
        component={props.component}
        exact={props.exact}
      />
    );
  }
};

AppRoute.propTypes = {
  path: PropTypes.string.isRequired,
  private: PropTypes.bool.isRequired,
  exact: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}