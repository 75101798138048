import React from 'react'
import { useForm, Controller } from "react-hook-form"
import { Card, Form, Button, Spinner } from 'react-bootstrap'
import { useFirebase } from 'react-redux-firebase'
import { Link, useHistory } from 'react-router-dom'
import { routes } from '../../../routes'
import useFirebaseError from '../../../hooks/useFirebaseError'

export default function SignInFormEmail({handleSignIn, handleError, loggingIn, error, userHomePath }) {
  const { control, handleSubmit } = useForm()
  const firebase = useFirebase()
  const history = useHistory()
  const { getErrorText } = useFirebaseError()

  const signInUser = ({ signInEmail, signInPassword }) => {
    handleSignIn('email', true)
    firebase
      .login({
        email: signInEmail,
        password: signInPassword
      })
      .then(() => {
        handleSignIn('email', false)
        history.push(userHomePath)
      })
      .catch((err) => {
        handleSignIn('email', false)
        handleError('email', getErrorText(err.code))
        
      })
  }

  return (
    <form onSubmit={handleSubmit(signInUser)}>
      <Form.Group>
        <Controller as={Form.Control} name='signInEmail' control={control} defaultValue="" placeholder='Email' type='email' />
      </Form.Group>
      <Form.Group>
        <Controller as={Form.Control} name='signInPassword' control={control} defaultValue="" placeholder='Κωδικός' type='password' />
        <Form.Text><small className="text-muted"><em><Link to={routes.forgotPassword.path}>Ξέχασα τον κωδικό μου</Link></em></small></Form.Text>
      </Form.Group>
      <Form.Group>
        <small className='text-danger'>{error}</small>
      </Form.Group>
      <Form.Group>
        <Button block variant='dark' type="submit">
          {loggingIn ?
            <Spinner animation="border" variant="light" size="sm" />
            :
            <>
              <i className='fas fa-sign-in-alt' style={{ marginRight: 10 }}></i>
              Είσοδος με email
            </>
          }
        </Button>
      </Form.Group>
      <Card.Text>
        <small className="text-muted"><em>Δεν έχεις λογαριασμό; <Link to={routes.signup.path}>Δημιούργησε δωρεάν</Link></em></small>
      </Card.Text>
    </form>
  )
}