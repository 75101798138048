import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { connect, useSelector } from 'react-redux'
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import BlockListNewItem from '../../Components/BlockListNewItem/BlockListNewItem'
import MyPage from '../../Components/Layout/MyPage'
import { MainLoader } from '../../Components/Loader/Loader'
import CreateFlat from './CreateFlat'

const mapStateToProps = state => {
  return {
    flats: state.firestore.ordered.flats,
    selectedBuilding: state.global.selectedBuilding
  }
}

const Flats = ({ flats, selectedBuilding }) => {
  const [submitting, setSubmitting] = useState(false)
  const [creating, setCreating] = useState(false)
  const { uid } = useSelector(state => state.firebase.auth)

  useFirestoreConnect({
    collection: `/users/${uid}/buildings/${selectedBuilding}/flats`,
    orderBy: ['number', 'asc'],
    storeAs: 'flats'
  })

  return (
    <MyPage title="Διαμερίσματα">

      <MainLoader loading={!isLoaded(flats)} />

      {flats && flats.map((flat, key) => (
        <Link to={`/flats/edit/${flat.id}`} onClick={(e) => e.stopPropagation()} className="edit-button" key={key}>
          <Card className="row-card" key={key}>
            {flat.number}{' - '}{flat.name}
            <i className="fas fa-pencil-alt"></i>
          </Card>
        </Link>
      ))}

      {isLoaded(flats) && isEmpty(flats) &&
        <div className="mb-3">Δεν υπάρχουν καταχωρημένα διαμερίσματα γι' αυτή την πολυκατοικία.</div>
      }

      {creating ?
        <CreateFlat submitting={submitting} setSubmitting={setSubmitting} creating={creating} setCreating={setCreating} selectedBuilding={selectedBuilding} />
        :
        <BlockListNewItem text="Πρόσθεσε ένα νέο διαμέρισμα" setCreating={setCreating} />
      }
    </MyPage>
  )
}

export default connect(mapStateToProps)(Flats)