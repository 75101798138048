import React from 'react'
import { BarLoader } from 'react-spinners'
import { css } from '@emotion/core'
import PropTypes from "prop-types";


export function MainLoader({ loading = true, variant="corn" }) {

  // Fix for 0.10.4
  const override = css({
    display: 'block'
  })

  if (loading) {
    return (
      <div className="mt-3 mb-3">
        <BarLoader
          css={override}
          width="100%"
          height={2}
          color={`var(--${variant})`}
        />
      </div>
    )
  } else {
    return <></>
  }
}

MainLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['dark', 'dark-lighter', 'corn', 'corn-darker'])
}