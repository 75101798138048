import React from 'react'
import { Card } from 'react-bootstrap'
import './MyFormSection.css'
import PropTypes from 'prop-types'


export default function MyFormSection({ children, headerText, headerTextAs }) {
  return (
    <Card className="MyFormSection">
      {headerText &&
        <Card.Header as={headerTextAs}>{headerText}</Card.Header>
      }
      <Card.Body>
        {children}
      </Card.Body>
    </Card>
  )
}

MyFormSection.propTypes = {
  headerText: PropTypes.string
}

MyFormSection.defaultProps = {
  headerText: '',
  headerTextAs: 'h6'
}