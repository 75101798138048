import { useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

export default function useUserFirestore() {
  
  const { uid } = useSelector((state) => state.firebase.auth)
  const firestore = useFirestore()

  const userFirestore = firestore.collection('users').doc(uid)
  return (
    userFirestore
  )
}
