import React from 'react'
import MyPageTitle from './MyPageTitle'
import PropTypes from 'prop-types'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function MyPage({title="Page title", children}) {
  return (
    <>
      <MyPageTitle>{title}</MyPageTitle>
      {children}
      <ToastContainer autoClose={3000} position="bottom-right" hideProgressBar />
    </>
  )
}

MyPage.defaultProps = {
  title: 'Page title'
}

MyPage.propTypes = {
  title: PropTypes.string.isRequired
}
