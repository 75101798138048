import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import Sidebar from "./Sidebar/Sidebar";
import TopBar from "./Topbar/Topbar";
import { Container } from "react-bootstrap";
import FirestoreConnect from "./FirestoreConnect";

export default function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          <FirestoreConnect>
            <TopBar />
            <Sidebar />
            <Container fluid id="content">
              {children}
            </Container>
          </FirestoreConnect>
        ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}
