import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { Link } from 'react-router-dom'
import BlockListItem from '../../Components/BlockListItem/BlockListItem'
import MyPage from '../../Components/Layout/MyPage'
import { MainLoader } from '../../Components/Loader/Loader'
import NextStep from '../../Components/NextStep/NextStep'
import useUserFirestore from '../../hooks/useUserFirestore'
import { routes } from '../../routes'
import CreatePublication from './CreatePublication'

const mapStateToProps = state => {
  return {
    publications: state.firestore.ordered.publications,
    selectedBuilding: state.global.selectedBuilding,
    selectedPublication: state.global.selectedPublication,
  }
}

function Publications({ publications, selectedBuilding, selectedPublication }) {
  const [submitting, setSubmitting] = useState(false)
  const userFirestore = useUserFirestore()
  const dispatch = useDispatch()

  const setDefaultPublication = (id) => {
    dispatch({
      type: "SET_PUBLICATION",
      payload: id
    })
  }

  const createPublication = (form) => {
    setSubmitting(true)

    userFirestore
      .collection('buildings')
      .doc(selectedBuilding)
      .collection('publications')
      .add({
        ...form,
        createdAt: Date.now()
      })
      .then(docRef => {
        setDefaultPublication(docRef.id)
        setSubmitting(false)
      })
      .catch((err) => {
        setSubmitting(false)
        alert(err)
      })
  }

  return (
    <MyPage title="Εκδόσεις κοινοχρήστων">

      {selectedPublication && publications ?
        <div className="mb-3">
          <NextStep route={routes.expenses} />
        </div> :
        <div className="mb-3">
          <Alert variant="warning">
            Δημιούργησε μια νέα έκδοση, ή επίλεξε μία από τις υπάρχουσες για να συνεχίσεις.
          </Alert>
        </div>
      }

      <div className="mb-3">Δημιούργησε μια νέα έκδοση</div>
      <CreatePublication createPublication={createPublication} submitting={submitting} />

      <MainLoader loading={!isLoaded(publications)} />

      {publications &&
        <div className="mb-3">ή επέλεξε μια από τις υπάρχουσες</div>
      }

      {publications && publications.map((publication, key) => {
        return (
          <BlockListItem linkUrl="#" key={key} id={publication.id} linkOnClick={setDefaultPublication} selected={selectedPublication === publication.id} >
            {publication.name}
            <Link to={`/publications/edit/${publication.id}`} onClick={(e) => e.stopPropagation()} className="edit-button">
              <i className="fas fa-pencil-alt"></i>
            </Link>
          </BlockListItem>
        )
      })
      }

      {isLoaded(publications) && isEmpty(publications) &&
        <div className="mb-3">Δεν υπάρχουν καταχωρημένες πολυκατοικίες.</div>
      }

    </MyPage>
  )
}

export default connect(mapStateToProps)(Publications)

