import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const mapStateToProps = state => {
  return {expanded: state.global.menuExpanded}
}

function ConnectedTopbarMenuButton({toggle, expanded}) {
  return (
    <Link id="topbar-menu-button" to="#" onClick={() => {toggle()}}>
      {expanded ? 
        <i className="fal fa-times"></i> :
        <i className="fal fa-bars"></i>
      }
    </Link>
  )
}

const TopbarMenuButton = connect(mapStateToProps)(ConnectedTopbarMenuButton)

export default TopbarMenuButton