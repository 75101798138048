import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import useSignOut from '../../hooks/useSignOut'
import SidebarDivider from './SidebarDivider'
import SidebarItem from './SidebarItem'
import SidebarLogo from './SidebarLogo'
import './Sidebar.css'
import { routes } from '../../routes'

const mapStateToProps = state => {
  return {
    expanded: state.global.menuExpanded,
    selectedBuilding: state.global.selectedBuilding,
    selectedPublication: state.global.selectedPublication,
    buildings: state.firestore.data.buildings,
    flats: state.firestore.data.flats,
    publications: state.firestore.data.publications,
    uid: state.firebase.auth.uid,
    profile: state.firestore.data.profile
  }
}

function Sidebar({ expanded, buildings, publications, selectedBuilding, selectedPublication }) {
  const { signOut } = useSignOut()

  return (
    <div id="sidebar" className={`${expanded && 'expanded'}`}>
      <div className="nav-menu mb-5">
        <SidebarLogo />

        <SidebarItem path={routes.buildings.path} text={routes.buildings.menuText} selected={selectedBuilding && buildings ? buildings[selectedBuilding].name : null} icon="fal fa-building" />
        <SidebarItem path={routes.flats.path} text={routes.flats.menuText} disabled={selectedBuilding === null} icon="fal fa-user-friends" />
        <SidebarItem path={routes.publications.path} text={routes.publications.menuText} disabled={selectedBuilding === null} selected={selectedPublication && publications ? publications[selectedPublication].name : null} icon="fal fa-file-alt" />

        <SidebarDivider />

        <SidebarItem path={routes.expenses.path} text={routes.expenses.menuText} disabled={selectedBuilding === null || selectedPublication === null} icon="fal fa-badge-dollar" />
        <SidebarItem path={routes.heating.path} text={routes.heating.menuText} disabled={!selectedBuilding || !selectedPublication} icon="fal fa-fire-alt" />
        <SidebarItem path={routes.debts.path} text={routes.debts.menuText} disabled={!selectedBuilding || !selectedPublication} icon="fas fa-hand-holding-usd" />

        <SidebarDivider />

      </div>
      <div className="user-menu">
        <SidebarItem path={routes.accountSettings.path} text={routes.accountSettings.menuText} icon="fal fa-cog" />

        <Link id="d-sidebar-signout" className="d-sidebar-item" to="#" onClick={() => signOut()}>
          <i className="fal fa-sign-out mr-2"></i>
          Αποσύνδεση
        </Link>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Sidebar)

