import { firebaseFrrors } from '../texts/firebaseErrors'

export default function useFirebaseError() {
  const pattern = '^[a-z]*/[a-z-]*$'

  const getErrorText = (text) => {

    try {
      if (text.match(pattern)) {
        const [category, item] = text.split('/')
        return firebaseFrrors[category][item]
      } else {
        throw new Error('The error code provided has wrong format.')
      }
    } catch (error) {
      console.error(error);
    }

  }

  return {
    getErrorText
  }
}
