import React from 'react'
import { Card } from 'react-bootstrap'
import "./MyRow.css"

export default function MyRow({ children }) {
  return (
    <Card className="row-card">
      <div style={{width: "100%"}}>{children}</div>
    </Card>
  )
}
