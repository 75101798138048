import { useDispatch } from "react-redux"
import { useFirebase } from "react-redux-firebase"
import { useHistory } from "react-router-dom"
import { actionTypes } from "redux-firestore"

const useSignOut = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const firebase = useFirebase()

  function signOut() {
    firebase
      .logout()
      .then(() => {
        dispatch({ type: actionTypes.CLEAR_DATA })
        dispatch({ type: "CLEAR_BUILDING"})
        dispatch({ type: "CLEAR_PUBLICATION"})
        history.push('/')
      })
  }

  return {
    signOut
  }
}

export default useSignOut