import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import SignInOther from './Components/SignInOther'
import SignInForm from './Components/SignInForm'
import SignUpForm from './Components/SignUpForm'
import TextDivider from '../../Components/TextDivider/TextDivider'
import AuthView from '../../Components/Layout/AuthView'
import AuthWidget from '../../Components/Layout/AuthWidget'
import './SignIn.css'
import { routes } from '../../routes'


export default function SignIn() {
  const [usingEmail, setUsingEmail] = useState(false)
  const [emailErr, setEmailErr] = useState(false)

  const [usingGoogle, setUsingGoogle] = useState(false)
  const [googleErr, setGoogleErr] = useState(false)

  const [usingFacebook, setUsingFacebook] = useState(false)
  const [facebookErr, setFacebookErr] = useState(false)

  const clearAllErrors = () => {
    setEmailErr(false)
    setGoogleErr(false)
    setFacebookErr(false)
  }

  const handleSignIn = (type, action) => {

    clearAllErrors()

    switch (type) {
      case 'email':
        setUsingEmail(action)
        break;
      case 'google':
        setUsingGoogle(action)
        break;
      case 'facebook':
        setUsingFacebook(action)
        break;
      default:
        console.error('No signin method specified calling handleSignIn() in SignIn.js');
        break;
    }
  }

  const handleError = (type, message) => {
    switch (type) {
      case 'email':
        setEmailErr(message)
        break;
      case 'google':
        setGoogleErr(message)
        break;
      case 'facebook':
        setFacebookErr(message)
        break;
      default:
        console.error('No error type specified calling handleError() in SignIn.js');
        break;
    }
  }

  const signInProps = {
    userHomePath: routes.home.path,
    handleSignIn,
    handleError
  }

  return (

    <AuthView>

      <AuthWidget disabled={usingEmail || usingGoogle || usingFacebook}>

        <Switch>
          <Route exact path='/signin'>
            <SignInForm
              {...signInProps}
              loggingIn={usingEmail}
              error={emailErr}
            />
          </Route>
          <Route exact path='/signup'>
            <SignUpForm
              {...signInProps}
              loggingIn={usingEmail}
              error={emailErr}
            />
          </Route>
        </Switch>

        <TextDivider text='ή' className="text-muted" />

        <SignInOther
          variation='facebook'
          text='Συνέχεια με Facebook'
          {...signInProps}
          loggingIn={usingFacebook}
          error={facebookErr}
        />

        <SignInOther
          variation='google'
          text='Συνέχεια με Google'
          {...signInProps}
          loggingIn={usingGoogle}
          error={googleErr}
          className='mt-3'
        />

      </AuthWidget>
    </AuthView>
  )
}
