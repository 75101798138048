import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

export default function SignInButtonGoogle(props) {
  const firebase = useFirebase()
  const history = useHistory()

  const signIn = () => {
    props.handleSignIn(props.variation, true)
    firebase
      .login({
        provider: props.variation,
        type: "popup",
      })
      .then(() => {
        props.handleSignIn(props.variation, false)
        history.push(props.userHomePath)
      })
      .catch((err) => {
        props.handleSignIn(props.variation, false)
        props.handleError(props.variation, err.message)
      })
  }

  return (
    <>
      <Button block
        variant={props.variation}
        className={props.className}
        onClick={(e) => {
          e.preventDefault()
          signIn()
        }}>
        {props.loggingIn ?
          <Spinner animation="border" size="sm" variant="light" />
          :
          <>
          <i className={`fab fa-${props.variation} mr-2`} ></i>
          {props.text}
        </>
        }
      </Button>
      { props.error && 
          <small className='mt-2 text-danger'>{props.error}</small>
      }
    </>
  )
}